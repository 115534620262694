import React from 'react';
import { Modal } from '../modal';

function HideAssignmentWarningModal({
  isUnitPageView = false,
  okCallback,
  cancelCallback,
  updateDoNotShowPopupSetting
}) {
  return (
    <Modal
      shouldShowDoNotShowAgainContainer
      title={isUnitPageView
        ? 'Hide assignment in a unit'
        : 'This assignment is currently assigned to a student or included in a unit'}
      onUpdateDoNotShowPopupSetting={() =>
        updateDoNotShowPopupSetting('AssignmentWillHideMessage')
      }
      okCallback={okCallback}
      cancelCallback={cancelCallback}
      okText="Hide Assignment" cancelText="Cancel">
      {isUnitPageView ?
        <p>
          This assignment will be hidden from teachers. References to the assignment 
          will still appear in lessons.
        </p>
        : (
        <p>
          This assignment will be hidden from teachers and students.
          However, if it's part of a unit, references to the hidden assignment(s)
          will still appear in lessons.
        </p>
        )
      }
    </Modal>
  )
}

export default HideAssignmentWarningModal;